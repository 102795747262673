import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { startLoading, stopLoading } from '../../../../@gc/common/actions/common.action';
import { updateMenuMailCount } from '../../../../@gc/navigation/actions';
import { updateFolder } from '../../contacts/actions';
import { pack } from '@gc';
import { uuid } from 'uuidv4';
import { message } from 'antd';

const jtf = require('json-form-data');

export const NEW_MAIL_RECEIVED = '[MAILBOX] NEW MAIL RECEIVED';
export const INIT_CHECKED_LIST = '[MAILBOX] INIT CHECKED LIST';
export const RELOAD_FOLDER = '[MAILBOX] RELOAD FOLDER';
export const FETCH_COUNTS = '[MAILBOX] FETCH COUNTS';
export const CHECK_ALL = '[MAILBOX] CHECK ALL';
export const TOGGLE_ALERT = '[MAILBOX] TOGGLE ALERT';
export const MAIL_SENDING = '[MAILBOX] MAIL SENDING';
export const REFRESH_LOADING = '[MAILBOX] REFRESH LOADING';
export const REFRESH_LOADED = '[MAILBOX] REFRESH LOADED';
export const FETCH_LOADING = '[MAILBOX] FETCH LOADING';
export const FETCH_SUGGESTIONS = '[MAILBOX] FETCH_SUGGESTIONS';
export const SELECT_EMAIL = '[MAILBOX] SELECT EMAIL';
export const FETCH_GROUP_EMAILS = '[MAILBOX] FETCH_GROUP_EMAILS';
export const FETCH_EMAILS_LOADING = '[MAILBOX] FETCH_EMAILS_LOADING';
export const COMMON_LOADING = '[MAILBOX] COMMON_LOADING';
export const FETCH_EMAIL = '[MAILBOX] FETCH_EMAIL';
export const FETCH_TEMPLATES = '[MAILBOX] FETCH TEMPLATES';
export const REMOVE_TEMPLATE = '[MAILBOX] REMOVE TEMPLATE';
export const OPEN_LIST = '[MAILBOX] OPEN LIST';
export const OPEN_ADD = '[MAILBOX] OPEN ADD';
export const OPEN_EDIT = '[MAILBOX] OPEN EDIT';
export const FETCH_CONTACTS_FOR_ENTITY = '[MAILBOX] FETCH CONTACTS FOR ENTITY';
export const FETCH_LIST = '[MAILBOX] FETCH LIST';
export const APPEND_LIST = '[MAILBOX] APPEND LIST';
export const REMOVE_FROM_LIST = '[MAILBOX] REMOVE FROM LIST';
export const NEW_SEEN_LIST = '[MAILBOX] NEW SEEN LIST';
export const CONFIRM_MAIL = '[MAILBOX] CONFIRM MAIL';
export const FETCH_ENTITIES_BY_FOLDER = '[MAILBOX] FETCH ENTITIES BY FOLDER';
export const FETCH_ENTITIES_BY_FOLDER_FIRST = '[MAILBOX] FETCH ENTITIES BY FOLDER FIRST';
export const FETCH_EMAILS_BY_FOLDER = '[MAILBOX] FETCH EMAILS BY FOLDER';
export const FETCH_ENTITY = '[MAILBOX] FETCH ENTITY';
export const FETCH_FOLDERS = '[MAILBOX] FETCH FOLDERS';
export const FETCH_OUTERS = '[MAILBOX] FETCH OUTERS';
export const REMOVE_ENTITY = '[MAILBOX] REMOVE ENTITY';
export const TOGGLE_BOX_TYPE = '[MAILBOX] TOGGLE BOX TYPE';
export const TOGGLE_SELECT_TYPE = '[MAILBOX] TOGGLE SELECT TYPE';
export const TOGGLE_IV_TYPE = '[MAILBOX] TOGGLE IV TYPE';
export const TOGGLE_STARRED = '[MAILBOX] TOGGLE STARRED';
export const TOGGLE_IMPORTANT = '[MAILBOX] TOGGLE IMPORTANT';
export const SET_SEARCH_TEXT = '[MAILBOX] SET SEARCH TEXT';
export const ADD_FOLDER = '[MAILBOX] ADD FOLDER';
export const EDIT_FOLDER = '[MAILBOX] EDIT FOLDER';
export const UPDATE_FOLDER = '[MAILBOX] UPDATE FOLDER';
export const REMOVE_FOLDER = '[MAILBOX] REMOVE FOLDER';
export const SELECT_ALL_CHECKS = '[MAILBOX] SELECT ALL CHECKS';
export const ADD_SELECT_ALL_CHECKS = '[MAILBOX] ADD SELECT ALL CHECKS';
export const DESELECT_ALL_CHECKS = '[MAILBOX] DESELECT ALL CHECKS';
export const UPDATE_CHECKED_LIST = '[MAILBOX] UPDATE CHECKED LIST';
export const CHECK_ROW = '[MAILBOX] CHECK ROW';
export const UNCHECK_ROW = '[MAILBOX] UNCHECK ROW';
export const REMOVE_CHECKED_LIST = '[MAILBOX] REMOVE CHECKED LIST';
export const CLEAR_CHECKED_LIST = '[MAILBOX] CLEAR CHECKED LIST';
export const MARK_AS_READ = '[MAILBOX] MARK AS READ';
export const SET_AS_READ_WHEN_OPEN = '[MAILBOX] SET_AS_READ_WHEN_OPEN';
export const MARK_AS_UNREAD = '[MAILBOX] MARK AS UNREAD';
export const MOVE_MAIL = '[MAILBOX] MOVE MAIL';
export const BLOCK_USER = '[MAILBOX] BLOCK USER';
export const PERMIT_USER_IMAGE = '[MAILBOX] PERMIT USER IMAGE';
export const SEND_REQUEST = '[MAILBOX] SEND REQUEST';
export const TEMPSAVE_REQUEST = '[MAILBOX] TEMPSAVE REQUEST';
export const TEMPLATE_SAVE_REQUEST = '[MAILBOX] TEMPLATE SAVE REQUEST';
export const TEMPLATE_LOAD_REQUEST = '[MAILBOX] TEMPLATE LOAD REQUEST';
export const TOGGLE_SEND_ENABLE = '[MAILBOX] TOGGLE SEND ENABLE';
export const TOGGLE_TEMPLATE_SAVABLE = '[MAILBOX] TOGGLE TEMPLATE SAVABLE';
export const TOGGLE_SEND_NOW = '[MAILBOX] TOGGLE SEND NOW';
export const ACT_REPLY = '[MAILBOX] ACT REPLY';
export const ACT_REPLY_ALL = '[MAILBOX] ACT REPLY ALL';
export const ACT_FORWARD = '[MAILBOX] ACT FORWARD';
export const OPEN_CONTACTS_DIALOG = '[MAILBOX] OPEN CONTACTS DIALOG';
export const CLOSE_CONTACTS_DIALOG = '[MAILBOX] CLOSE CONTACTS DIALOG';
export const TOGGLE_CAN_BLOCK = '[MAILBOX] TOGGLE CAN BLOCK';
export const REMOVE_SIGN = '[MAILBOX] REMOVE SIGN';
export const FETCH_SIGNS = '[MAILBOX] FETCH SIGNS';
export const FETCH_DEFAULT_SIGN = '[MAILBOX] FETCH DEFAULT SIGN';
export const STOP_OUTER_LOADING = '[MAILBOX] STOP OUTER LOADING';
export const START_OUTER_LOADING = '[MAILBOX] START OUTER LOADING';
export const MOVE_LOADER_ACTIVE = '[MAILBOX] MOVE LOADER ACTIVE';
export const TOGGLE_SIDE_EXPANDED = '[MAILBOX] TOGGLE SIDE EXPANDED';
export const SELECT_TAG = '[MAILBOX] SELECT TAG';
export const REMOVE_TAG = '[MAILBOX] REMOVE TAG';
export const APPEND_TAG = '[MAILBOX] APPEND TAG';
export const UPDATE_TAG = '[MAILBOX] UPDATE TAG';
export const FETCH_TAGS = '[MAILBOX] FETCH TAGS';
export const MAP_TAG = '[MAILBOX] MAP TAG';
export const UNMAP_TAG = '[MAILBOX] UNMAP TAG';
export const TOGGLE_PREVIEW = '[MAILBOX] TOGGLE PREVIEW';
export const SEARCH_DETAIL = '[MAILBOX] SEARCH DETAIL';
export const SEARCH_DETAIL_INIT = '[MAILBOX] SEARCH DETAIL INIT';
export const FETCH_SEARCHS = '[MAILBOX] FETCH SEARCHS';
export const TOGGLE_EMAIL_FROM_DIALOG = '[MAILBOX] TOGGLE EMAIL FROM DIALOG';
export const MAIL_FROM_ADD = '[MAILBOX] MAIL FROM ADD';
export const MAIL_FROM_REMOVE = '[MAILBOX] MAIL FROM REMOVE';
export const MAIL_FROM_REMOVE_ALL = '[MAILBOX] MAIL FROM REMOVE ALL';
export const INIT_MAILFROM = '[MAILBOX] INIT MAILFROM';
export const SET_CHECKED_MAILFROM_EMP = '[MAILBOX] SET_CHECKED_MAILFROM_EMP';
export const SET_CHECKED_MAILFROM_CONTACTS = '[MAILBOX] SET_CHECKED_MAILFROM_CONTACTS';
export const FETCH_EMP_TREE = '[MAILBOX] FETCH EMP TREE';
export const FETCH_CONTACTS_TREE = '[MAILBOX] FETCH CONTACTS TREE';
export const FETCH_QUOTA = '[MAILBOX] FETCH QUOTA';
export const TOGGLE_REMOVE_ACTIVE = '[MAILBOX] TOGGLE REMOVE ACTIVE';
export const SET_CLEAR_RATE = '[MAILBOX] SET CLEAR RATE';
export const CLEAR_FOLDER = '[MAILBOX] CLEAR FOLDER';
export const SEND_EMAIL = '[MAILBOX] SEND EMAIL';
export const SET_PAGE_INFO = '[MAILBOX] SET PAGE INFO';

export const MAILBOX_TYPE_TOTAL = '[MAILBOX] TYPE TOTAL BOX';
export const MAILBOX_TYPE_ALL = '[MAILBOX] TYPE ALL BOX';
export const MAILBOX_TYPE_INBOX = '[MAILBOX] TYPE INBOX';
export const MAILBOX_TYPE_SEND = '[MAILBOX] TYPE SEND';
export const MAILBOX_TYPE_TOME = '[MAILBOX] TYPE TOME';
export const MAILBOX_TYPE_STAR = '[MAILBOX] TYPE STAR';
export const MAILBOX_TYPE_IMPORTANT = '[MAILBOX] TYPE IMPORTANT';
export const MAILBOX_TYPE_TEMP = '[MAILBOX] TYPE TEMP';
export const MAILBOX_TYPE_SPAM = '[MAILBOX] TYPE SPAM';
export const MAILBOX_TYPE_RECYCLE = '[MAILBOX] TYPE RECYCLE';
export const MAILBOX_TYPE_ETC = '[MAILBOX] TYPE ETC';
export const MAILBOX_TYPE_UNREAD = '[MAILBOX] TYPE UNREAD';
export const MAILBOX_TYPE_TAG = '[MAILBOX] TYPE TAG';
export const MAILBOX_TYPE_FILE = '[MAILBOX] TYPE FILE';
export const MAILBOX_TYPE_SEARCHBOX = '[MAILBOX] TYPE SEARCHBOX';

export const MAILBOX_SELTYPE_TOTAL = '[MAILBOX] SELTYPE TOTAL';
export const MAILBOX_SELTYPE_ALL = '[MAILBOX] SELTYPE ALL';
export const MAILBOX_SELTYPE_READ = '[MAILBOX] SELTYPE READ';
export const MAILBOX_SELTYPE_UNREAD = '[MAILBOX] SELTYPE UNREAD';
export const MAILBOX_SELTYPE_STAR = '[MAILBOX] SELTYPE STAR';
export const MAILBOX_SELTYPE_IMPORTANT = '[MAILBOX] SELTYPE IMPORTANT';
export const MAILBOX_SELTYPE_UNCHECK = '[MAILBOX] SELTYPE UNCHECK';
export const MAILBOX_ENABLE_FETCH = '[MAILBOX] ENABLE FETCH';

export const MAILBOX_IVTYPE_ALL = '2';
export const MAILBOX_IVTYPE_READ = '1';
export const MAILBOX_IVTYPE_UNREAD = '0';

export const DEFAULT_FOLDER_MAP = {
   [MAILBOX_TYPE_INBOX]: 'inbox',
   [MAILBOX_TYPE_TEMP]: 'temp',
   [MAILBOX_TYPE_SEND]: 'send',
   [MAILBOX_TYPE_TOME]: 'send',
   [MAILBOX_TYPE_RECYCLE]: 'recycle',
   [MAILBOX_TYPE_SPAM]: 'spam',
};

// fetchEmailList 에서 서버로 넘길 폴더명은 이걸로 지정
export const SECOND_FOLDER_MAP = {
   [MAILBOX_TYPE_ALL]: '`all`',
   [MAILBOX_TYPE_UNREAD]: '`unread`',
   [MAILBOX_TYPE_INBOX]: 'inbox',
   [MAILBOX_TYPE_TEMP]: 'temp',
   [MAILBOX_TYPE_SEND]: 'send',
   [MAILBOX_TYPE_TOME]: '`tome`',
   [MAILBOX_TYPE_RECYCLE]: 'recycle',
   [MAILBOX_TYPE_SPAM]: 'spam',
   [MAILBOX_TYPE_STAR]: '`star`',
   [MAILBOX_TYPE_IMPORTANT]: '`important`',
   [MAILBOX_TYPE_FILE]: '`file`',
   [MAILBOX_TYPE_TAG]: '`tag`',
   [MAILBOX_TYPE_SEARCHBOX]: '`search`', // 검색메일함 클릭시
};

const EXCEPT_FROM_READ_COUNT = ['spam', 'recycle', 'send'];

export function enableFetch(flag) {
   return dispatch =>
      dispatch({
         type: MAILBOX_ENABLE_FETCH,
         payload: flag,
      });
}

export function fetchTemplates() {
   return (dispatch, getState) =>
      axios.get(`/mail/templates/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_TEMPLATES,
            payload: res.data,
         }),
      );
}

export function fetchGroupEmails() {
   return (dispatch, getState) => {
      axios.get(`/mail/groupmails/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_GROUP_EMAILS,
            payload: res.data,
         }),
      );
   };
}

export function fetchQuota() {
   return (dispatch, getState) => {
      axios
         .get(`/mail/quota/${getState().gc.user.currentEmail}`)
         .then(res => {
            const info = res.data;
            dispatch({
               type: FETCH_QUOTA,
               limit: info.limit,
               usage: info.usage,
               strings: getState().gc.common.strings,
            });
         })
         .catch(e => pack.showError(e));
   };
}

export function commonLoading(flag) {
   return {
      type: COMMON_LOADING,
      flag,
   };
}

export function refreshList(shadowMode, email) {
   return (dispatch, getState) => {
      if (!shadowMode) {
         dispatch({
            type: REFRESH_LOADING,
         });
      }
      const { removeActive } = getState().griffin.activity.mailbox;
      const { uploadActive } = getState().griffin.activity.mailsetting;
      if (!removeActive && !uploadActive) {
         axios
            .get(`/mail/refresh/${email || getState().gc.user.currentEmail}`)
            .then(res => {
               dispatch({ type: REFRESH_LOADED });
            })
            .catch(e => {
               dispatch({ type: REFRESH_LOADED });
            });
      }
   };
}

/*export function fetchList(showLoading, props) {
   return (dispatch) => {
      if(showLoading)
         dispatch(startLoading());
      dispatch(fetchJustList(props));
      dispatch(refreshList(false, props));
      dispatch(updateMenuMailCount());
   }
}*/

export function initCheckedList() {
   return {
      type: INIT_CHECKED_LIST,
   };
}

export function setCheckall(param) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      const { inboxViewType, boxType, folderName, searchText, condition } = getState().griffin.activity.mailbox;
      let isread = null;
      switch (inboxViewType) {
         case MAILBOX_IVTYPE_READ:
            isread = '1';
            break;
         case MAILBOX_IVTYPE_UNREAD:
            isread = '0';
            break;
         default:
            if (boxType === MAILBOX_TYPE_UNREAD) {
               isread = '0';
            } else {
               isread = null;
            }
      }

      axios
         .post(
            '/mail/checkall',
            JSON.stringify({
               ...param,
               folderName: param.folderName || SECOND_FOLDER_MAP[boxType] || folderName,
               email: getState().gc.user.currentEmail,
               search: param.search || searchText,
               isread,
               condition: condition.search ? _.omit(condition, ['search']) : null,
            }),
         )
         .then(res => {
            message.success(strings.mailCheckSuccess.replace(/#1/, res.data.length), 1);
            dispatch({
               type: CHECK_ALL,
               payload: res.data,
            });
         });
   };
}

export function fetchEmailLoading(flag) {
   return {
      type: FETCH_EMAILS_LOADING,
      flag,
   };
}

export function fetchCounts() {
   return (dispatch, getState) => {
      const {
         user: { currentEmail },
      } = getState().gc;

      if (currentEmail) {
         axios.get(`/mail/counts/${currentEmail}`).then(res => {
            dispatch({
               type: FETCH_COUNTS,
               payload: res.data,
            });
            dispatch(updateMenuMailCount(res.data.unread));
         });
      }
   };
}

export function fetchEmailList(param, useCache) {
   return (dispatch, getState) => {
      dispatch(fetchEmailLoading(true));
      const { inboxViewType, boxType, folderName, searchText, condition: cond } = getState().griffin.activity.mailbox;
      let isread = null;
      switch (inboxViewType) {
         case MAILBOX_IVTYPE_READ:
            isread = '1';
            break;
         case MAILBOX_IVTYPE_UNREAD:
            isread = '0';
            break;
         default:
            if (boxType === MAILBOX_TYPE_UNREAD) {
               isread = '0';
            } else {
               isread = null;
            }
      }

      const cached = localStorage.getItem('mailbox-param');

      if (!param) {
         param = {
            page: 0,
            size: cached ? JSON.parse(cached).size : pack.pageSizes[0],
         };
      } else if (!String(param.page) || !param.size) {
         param = {
            ...param,
            page: 0,
            size: cached ? JSON.parse(cached).size : pack.pageSizes[0],
         };
      }

      const condition = param.condition || cond;

      const json =
         useCache && Boolean(cached)
            ? cached
            : JSON.stringify({
                 ...param,
                 folderName: param.folderName || SECOND_FOLDER_MAP[boxType] || folderName,
                 email: getState().gc.user.currentEmail,
                 search: param.search || searchText,
                 isread,
                 condition: condition.search ? _.omit(condition, ['search', 'boxType']) : null,
              });

      // Home 화면에서 size = 6 으로 fetch할대의 상태정보는 저장하지 않기 위한 조건절
      if (param.size && param.size >= pack.pageSizes[0]) localStorage.setItem('mailbox-param', json);

      axios
         .post('/mail/list', json)
         .then(res => {
            const { list, total, whole, unread } = res.data;
            dispatch({
               type: FETCH_EMAILS_BY_FOLDER,
               list: list.map(item => ({
                  ...item,
                  filesize: item.files.map(file => file.filesize).reduce((sum, cur) => sum + cur, 0),
               })),
               total,
               whole,
               unread,
            });
         })
         .catch(e => {
            console.error(e);
            pack.showError(e);
            dispatch(fetchEmailLoading(false));
         });
   };
}

function fetchEntitiesByFolder(folderName, page) {
   return (dispatch, getState) => {
      axios.post('/mail/just-infolder', jtf({ folderName, email: getState().gc.user.currentEmail, page })).then(res => {
         dispatch({
            type: FETCH_ENTITIES_BY_FOLDER,
            list: res.data,
            folder: folderName,
            page: page + 1,
         });
      });
   };
}

export function fetchFirstList(onFinish) {
   return (dispatch, getState) => {
      axios.post('/mail/just-infolder/first', jtf({ folderName: 'inbox', email: getState().gc.user.currentEmail, page: 0 })).then(res => {
         onFinish();
         const { list, folders, size, total } = res.data;
         const inbox = _.find(folders, { name: 'inbox' });
         const pages = Math.ceil(inbox.mailCount / size);
         dispatch({
            type: FETCH_ENTITIES_BY_FOLDER_FIRST,
            list: list.map(item => ({
               ...item,
               filesize: item.files.map(file => file.filesize).reduce((sum, cur) => sum + cur, 0),
            })),
            page: 1,
            folder: 'inbox',
            folders,
            total,
         });
         if (inbox.mailCount > size) {
            // size 는 EmailVO 에서 관리한다.
            for (let i = 1; i < pages; i++) {
               if (getState().griffin.activity.mailbox.fetchable) {
                  dispatch(fetchEntitiesByFolder('inbox', i));
               } else {
                  break;
               }
            }
         }

         folders
            .filter(f => f.name !== 'inbox')
            .forEach(folder => {
               if (folder.mailCount > 0) {
                  const pages = Math.ceil(folder.mailCount / size);
                  for (let i = 0; i < pages; i++) {
                     dispatch(fetchEntitiesByFolder(folder.name, i));
                  }
               }
            });
      });
   };
}

/* 메일서버와 DB 의 동기화(EmailService.java - syncEngine) 없이 db 의 메일을 가져오기만 한다 */
export function fetchJustList(props, onFinish) {
   return (dispatch, getState) => {
      axios(`/mail/just-list/${getState().gc.user.currentEmail}`).then(res => {
         dispatch({
            type: FETCH_LIST,
            payload: res.data.map(item => ({
               ...item,
               filesize: item.files.map(file => file.filesize).reduce((sum, cur) => sum + cur, 0),
            })),
         });
         if (getState().gc.common.currentItem.id === 'mail' && props && Boolean(props.match.params.seq)) {
            dispatch(fetchEntity(props.match.params.seq));
         }
         if (typeof onFinish === 'function') {
            onFinish();
         }
      });
   };
}

export function fetchEntity(seq) {
   return (dispatch, getState) => {
      const data = _.find(getState().griffin.activity.mailbox.entities, { seq });
      const mails = [data.fromAddress, ...data.tos.map(it => it.address), ...data.ccs.map(it => it.address), ...data.bccs.map(it => it.address)];

      axios({
         method: 'post',
         url: '/mail/contacts',
         data: jtf({ address: mails.join(','), empno: getState().gc.user.empno }),
      }).then(res =>
         dispatch({
            type: FETCH_ENTITY,
            entity: data,
            contacts: res.data,
         }),
      );
   };
}

export function fetchFolders() {
   return (dispatch, getState) => {
      axios.get(`/mail/folder/list/${getState().gc.user.currentEmail}`).then(res =>
         dispatch({
            type: FETCH_FOLDERS,
            payload: res.data,
         }),
      );
   };
}

export function fetchOuters(showLoading, email) {
   return (dispatch, getState) => {
      if (showLoading) {
         dispatch(startLoading());
      }
      axios.get(`/mail/outers/${email || getState().gc.user.currentEmail}`).then(res => {
         dispatch({
            type: FETCH_OUTERS,
            payload: res.data,
         });
         dispatch(stopLoading());
      });
   };
}

export function checkOuterValid(seq, email) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      axios.patch(`/mail/bring/check/${seq}`).then(res => {
         if (res.data.issuccess === '0') {
            toastr.error(strings.checkValidFail, res.data.msg);
         } else {
            dispatch(fetchOuters(false, email));
         }
      });
   };
}

export function fetchSuggestions() {
   return (dispatch, getState) =>
      axios.get(`/mail/suggestion/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_SUGGESTIONS,
            payload: res.data,
         }),
      );
}

export function selectEmail(email) {
   return {
      type: SELECT_EMAIL,
      email,
   };
}

export function sending() {
   return {
      type: MAIL_SENDING,
   };
}

export function saveOuter(data, email) {
   return dispatch => {
      axios({
         method: 'post',
         url: '/mail/outer',
         data: jtf({
            ..._.omit(data, ['employee']),
            empEmail: email,
         }),
      }).then(res => {
         dispatch(fetchOuters(false, email));
         dispatch(checkOuterValid(res.data.seq));
      });
   };
}

export function removeOuter(seq) {
   return (dispatch, getState) => {
      axios.delete(`/mail/outer/${seq}`).then(res => dispatch(fetchOuters()));
   };
}

export function bringOuter(row, email) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      if (row.seq) {
         dispatch(startOuterLoading(row.seq));
      }
      axios({
         method: 'post',
         url: '/mail/bring',
         data: jtf({
            ..._.omit(row, ['employee']),
            empEmail: email,
         }),
      })
         .then(res => {
            const { data } = res;
            dispatch(stopOuterLoading(row.seq));
            if (data.code === 'OK') {
               toastr.success(strings.infoTitle, strings.successMessage);
               dispatch(fetchJustList());
            } else {
               toastr.error('Error', `${row.address} : Download error, ${data.msg}`);
               console.log(row.address, ' : ', data.msg);
            }
         })
         .catch(e => {
            console.error(e);
            dispatch(stopOuterLoading(row.seq));
            pack.showError(e);
         });
   };
}

export function stopOuterLoading(seq) {
   return {
      type: STOP_OUTER_LOADING,
      seq,
   };
}

export function startOuterLoading(seq) {
   return {
      type: START_OUTER_LOADING,
      seq,
   };
}

// export function openList() {
//    return {
//       type: OPEN_LIST
//    };
// }

export function openSetting({ history, match }) {
   return dispatch => {
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'setting',
            seq: null,
         }),
      );
   };
}

export function openAddFromOutside(tos) {
   return {
      type: OPEN_ADD,
      data: { tos },
   };
}

export function openAdd({ history, match }, toMe = false) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      dispatch({
         type: OPEN_ADD,
         data: toMe ? { tos: [{ name: user.name, address: user.currentEmail }] } : {},
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function openTempSaved(data, { history, match }) {
   return (dispatch, getState) => {
      dispatch({
         type: OPEN_ADD,
         data,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function fetchContactsForEntity(data) {
   return (dispatch, getState) => {
      const mails = [data.fromAddress, ...data.tos.map(it => it.address), ...data.ccs.map(it => it.address), ...data.bccs.map(it => it.address)];

      axios({
         method: 'post',
         url: '/mail/contacts',
         data: jtf({ address: mails.join(','), empno: getState().gc.user.empno }),
      }).then(res => {
         dispatch({
            type: FETCH_CONTACTS_FOR_ENTITY,
            payload: res.data,
         });
      });
   };
}

export function openView(seq, { history, match }) {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      const { entities, boxType } = getState().griffin.activity.mailbox;
      const entity = _.find(entities, { seq });

      if (!entity) {
         console.log('fetch Entity : 네트워크 로딩');
         axios.get(`/mail/${user.empno}/${seq}`).then(res => {
            dispatch({
               type: OPEN_EDIT,
               entity: res.data,
            });
            dispatch(fetchContactsForEntity(res.data));
         });
      } else {
         console.log('fetch Entity : Local 로딩');
         dispatch({
            type: OPEN_EDIT,
            entity,
         });
         dispatch(fetchContactsForEntity(entity));
      }

      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            seq,
         }),
      );
      // const unreadList = entities.filter(mail => !EXCEPT_FROM_READ_COUNT.includes(mail.folderName)).filter(mail => mail.isread !== '1');
      dispatch({
         type: SET_AS_READ_WHEN_OPEN,
         seq,
      });
      axios.get(`/mail/read/${seq}`).then(res => {
         dispatch(fetchCounts());
      });
   };
}

export function removeEntity(seq) {
   return dispatch =>
      axios.put('/mail/delete', jtf({ seq })).then(res => {
         dispatch({
            type: REMOVE_ENTITY,
            seq,
         });
      });
}

export function reloadFolder() {
   return {
      type: RELOAD_FOLDER,
   };
}

export function toggleBoxType(type, folderName, { history, match }, isSearchMode) {
   return dispatch => {
      dispatch(setSearchText(''));

      if (!isSearchMode) {
         dispatch({
            type: SEARCH_DETAIL_INIT,
         });
      }

      if (type !== MAILBOX_TYPE_TAG) {
         dispatch(selectTag(null));
      }

      dispatch({
         type: TOGGLE_BOX_TYPE,
         payload: type,
         folderName: folderName || DEFAULT_FOLDER_MAP[type],
      });
      dispatch(toggleInboxViewType(MAILBOX_IVTYPE_ALL));

      if (history && match && match.params.mode !== 'list') {
         history.push(
            pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null,
            }),
         );
      }
   };
}

export function toggleSelectType(seltype) {
   return {
      type: TOGGLE_SELECT_TYPE,
      seltype,
   };
}

export function toggleInboxViewType(ivtype) {
   return {
      type: TOGGLE_IV_TYPE,
      ivtype,
   };
}

export function saveFolder({ seq, name, withContacts }) {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      axios({
         method: 'post',
         url: `/mail/folder`,
         data: jtf({
            email: user.currentEmail,
            seq,
            name,
         }),
      })
         .then(res => {
            dispatch(fetchFolders());
            if (Boolean(seq)) {
               dispatch({
                  type: UPDATE_FOLDER,
                  name,
                  oldName: res.data,
               });
            }
            if (withContacts) {
               dispatch(
                  updateFolder({
                     seq: null,
                     parentSeq: '0',
                     name,
                     userId: getState().gc.user.id,
                  }),
               );
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function addFolder() {
   return {
      type: ADD_FOLDER,
   };
}

/*export function editFolder(name) {
   return {
      type: EDIT_FOLDER,
      name
   }
}*/

export function removeFolder({ seq, name }) {
   return (dispatch, getState) => {
      axios
         .delete(`/mail/folder/${getState().gc.user.currentEmail}/${seq}`)
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: REMOVE_FOLDER,
                  seq,
                  name,
               });
               if (getState().griffin.activity.mailbox.folderName === name) dispatch(toggleBoxType(MAILBOX_TYPE_INBOX, null, {}));
               // dispatch(fetchJustList());
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function setSearchText(text) {
   return {
      type: SET_SEARCH_TEXT,
      text,
   };
}

export function addSelectAllChecks(list) {
   return {
      type: ADD_SELECT_ALL_CHECKS,
      list,
   };
}

export function selectAllChecks(list) {
   return {
      type: SELECT_ALL_CHECKS,
      list,
   };
}

export function deSelectAllChecks() {
   return {
      type: DESELECT_ALL_CHECKS,
   };
}

// export function updateCheckedList(list) {
//    return {
//       type: UPDATE_CHECKED_LIST,
//       list
//    };
// }

export function checkRow(seq) {
   return {
      type: CHECK_ROW,
      seq,
   };
}

export function unCheckRow(seq) {
   return {
      type: UNCHECK_ROW,
      seq,
   };
}

/* ACT TYPE */
export function actRemove(seq, onSuccess) {
   return (dispatch, getState) => {
      const { checkedList, folderName } = getState().griffin.activity.mailbox;

      dispatch(commonLoading(true));

      axios({
         method: 'post',
         url: '/mail/remove',
         data: jtf({
            empno: getState().gc.user.empno,
            list: seq || checkedList.join(','),
            fromFolder: folderName,
         }),
      })
         .then(res => {
            dispatch(initCheckedList());
            dispatch(fetchEmailList());
            dispatch(fetchCounts());
            if (typeof onSuccess === 'function') onSuccess();
            dispatch(commonLoading(false));
         })
         .catch(e => {
            pack.showError(e);
            dispatch(commonLoading(false));
         });
   };
}

/* 스팸함, 휴지통 비우기 */
export function clearFolder(folderName) {
   return (dispatch, getState) => {
      dispatch(commonLoading(true));
      dispatch(toggleRemoveActive(true));
      axios
         .get(`/mail/clear/${folderName}/${getState().gc.user.currentEmail}`)
         .then(res => {
            /*dispatch({
               type: CLEAR_FOLDER,
               payload: res.data,
            });*/
            dispatch(toggleRemoveActive(false));
            dispatch(fetchEmailList());
            dispatch(fetchCounts());
            dispatch(fetchQuota());
            dispatch(commonLoading(false));
         })
         .catch(e => {
            pack.showError(e);
            dispatch(toggleRemoveActive(false));
            dispatch(commonLoading(false));
         });
   };
}

export function toggleRemoveActive(flag) {
   return {
      type: TOGGLE_REMOVE_ACTIVE,
      flag,
   };
}

function executeMailClear(data, islast, onSuccess) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/mail/clear',
         data,
      })
         .then(res => {
            if (res.status === 200) {
               if (islast) {
                  dispatch({
                     type: CLEAR_CHECKED_LIST,
                     list: getState().griffins.activity.mailbox.checkedList,
                  });
                  dispatch(fetchQuota());
                  if (typeof onSuccess === 'function') onSuccess();
                  dispatch(toggleRemoveActive(false));
               }
            } else {
               if (islast) {
                  dispatch(toggleRemoveActive(false));
               }
               console.log('error occured while sweeping.\n' + res.error);
            }
            // dispatch(fetchList(false));
         })
         .catch(e => {
            pack.showError(e);
            if (islast) {
               dispatch(toggleRemoveActive(false));
            }
         });
}

export function actRemoveForever(seq, onSuccess) {
   return (dispatch, getState) => {
      dispatch(toggleRemoveActive(true));
      dispatch(commonLoading(true));

      const { checkedList } = getState().griffin.activity.mailbox;
      let pbarTimer = null;
      const randomId = uuid();

      axios({
         method: 'post',
         url: '/mail/clear',
         data: jtf({
            list: seq || checkedList.join(','),
            email: getState().gc.user.currentEmail,
            randomId,
            folderName: getState().griffin.activity.mailbox.folderName,
         }),
      })
         .then(res => {
            dispatch(initCheckedList());
            dispatch(toggleRemoveActive(false));
            dispatch(fetchEmailList());
            dispatch(fetchCounts());
            dispatch(fetchQuota());
            if (typeof onSuccess === 'function') onSuccess();
            dispatch(commonLoading(false));
         })
         .catch(e => {
            pack.showError(e);
            dispatch(toggleRemoveActive(false));
            dispatch(commonLoading(false));
         });

      if (checkedList.length > 100) {
         pbarTimer = setInterval(() => {
            axios.post('/progress', jtf({ randomId })).then(res => {
               if (res.data.finished) {
                  clearInterval(pbarTimer);
                  pbarTimer = null;
               }
               dispatch(setClearRate(res.data));
            });
         }, 300);
      }
   };
}

export function setClearRate({ progressRate, total, message }) {
   return {
      type: SET_CLEAR_RATE,
      rate: progressRate,
      total,
      message,
   };
}

export function actRead() {
   return (dispatch, getState) => {
      const { checkedList } = getState().griffin.activity.mailbox;
      dispatch(commonLoading(true));
      dispatch({
         type: MARK_AS_READ,
      });
      /*checkedList.forEach(seq => {
         _.find(entities, { seq }).isread = '1';
      });*/
      // const unreadList = entities.filter(mail => !EXCEPT_FROM_READ_COUNT.includes(mail.folderName)).filter(mail => mail.isread !== '1');
      axios({
         method: 'post',
         url: '/mail/mark-read',
         data: jtf({
            list: checkedList.join(','),
            empno: getState().gc.user.empno,
         }),
      })
         .then(res => {
            // dispatch(initCheckedList());
            dispatch(fetchCounts());
            dispatch(commonLoading(false));
         })
         .catch(e => {
            pack.showError(e);
            dispatch(commonLoading(false));
         });
   };
}

export function actUnread() {
   return (dispatch, getState) => {
      const { checkedList, entities } = getState().griffin.activity.mailbox;
      dispatch(commonLoading(true));
      dispatch({
         type: MARK_AS_UNREAD,
      });

      // checkedList.forEach(seq => {
      //    _.find(entities, { seq }).isread = '0';
      // });
      // const unreadList = entities.filter(mail => !EXCEPT_FROM_READ_COUNT.includes(mail.folderName)).filter(mail => mail.isread !== '1');
      axios({
         method: 'post',
         url: '/mail/mark-unread',
         data: jtf({
            list: checkedList.join(','),
            empno: getState().gc.user.empno,
         }),
      })
         .then(res => {
            // dispatch(initCheckedList());
            dispatch(fetchCounts());
            dispatch(commonLoading(false));
         })
         .catch(e => {
            pack.showError(e);
            dispatch(commonLoading(false));
         });
   };
}

export function actMove(folder, moveAlways, moveToo, onSuccess) {
   return (dispatch, getState) => {
      dispatch({ type: MOVE_LOADER_ACTIVE, flag: true });
      const { checkedList } = getState().griffin.activity.mailbox;
      const fromFolder = getState().griffin.activity.mailbox.folderName;
      axios({
         method: 'post',
         url: '/mail/move',
         data: jtf({
            list: checkedList.join(','),
            fromFolder,
            folder,
            always: moveAlways ? '1' : '0',
            too: moveToo ? '1' : '0',
            email: getState().gc.user.currentEmail,
         }),
      })
         .then(res => {
            dispatch(initCheckedList());
            dispatch(fetchEmailList());
            dispatch(fetchCounts());
            if (typeof onSuccess === 'function') {
               onSuccess(res.data);
            }
            dispatch({ type: MOVE_LOADER_ACTIVE, flag: false });
         })
         .catch(e => {
            pack.showError(e);
            dispatch({ type: MOVE_LOADER_ACTIVE, flag: false });
         });
   };
}

/**
 * blockOne: view 모드에서 개별적으로 수신거부한 경우 true / list 모드에서 일괄 수신거부한 경우 undefined
 *
 */
export function actBlock(seq, note, blockOne, props) {
   return (dispatch, getState) => {
      const { checkedList, boxType } = getState().griffin.activity.mailbox;
      dispatch(commonLoading(true));

      const url = boxType === MAILBOX_TYPE_SPAM ? '/mail/unblocks' : '/mail/block';

      axios({
         method: 'post',
         url,
         data: jtf({
            list: !seq ? checkedList.join(',') : seq,
            note,
            empno: getState().gc.user.empno,
         }),
      })
         .then(res => {
            if (blockOne) {
               toastr.success(pack.strings.infoTitle, pack.strings.successMessage);
               // dispatch(fetchJustList());
               dispatch({
                  type: TOGGLE_CAN_BLOCK,
                  email: res.data,
                  canBlock: boxType === MAILBOX_TYPE_SPAM,
               });
               if (Boolean(props)) {
                  props.history.goBack();
               }
            }
            dispatch(initCheckedList());
            dispatch(fetchEmailList());
            dispatch(fetchCounts());
            dispatch(commonLoading(false));
            /*dispatch({
               type: BLOCK_USER,
               blocked: Boolean(seq) ? [seq] : checkedList,
            });*/
         })
         .catch(e => {
            pack.showError(e);
            dispatch(commonLoading(false));
         });
   };
}
export function actUnblock(seq) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/mail/unblock',
         data: jtf({ seq, empno: getState().gc.user.empno }),
      }).then(res => {
         if (res.status === 200) {
            toastr.success(pack.strings.infoTitle, pack.strings.successMessage);
            dispatch(fetchJustList());
            dispatch({
               type: TOGGLE_CAN_BLOCK,
               email: res.data,
               canBlock: true,
            });
         } else {
            console.log('error occured while blocking emails.\n' + res.error);
         }
      });
   };
}
/* ACT TYPE */

/* ACT TYPE 2 */
export function actReply(data, { history, match }) {
   return (dispatch, getState) => {
      const text = getReplyText(data, getState);

      dispatch({
         type: ACT_REPLY,
         data: {
            seq: data.seq,
            id: data.id,
            isreply: true,
            subject: '[RE] ' + data.subject,
            tos: [{ name: data.fromName, address: data.fromAddress }],
            text,
         },
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function actReplyAll(data, { history, match }) {
   return (dispatch, getState) => {
      const text = getReplyText(data, getState);

      const myEmails = getState().gc.user.emails;

      dispatch({
         type: ACT_REPLY_ALL,
         data: {
            seq: data.seq,
            id: data.id,
            isreply: true,
            subject: '[RE] ' + data.subject,
            tos: [
               ...data.tos.filter(to => !myEmails.some(e => e.email === to.address)),
               // ...(data.ccs || []).filter(to => !myEmails.some(e => e.email === to.address)),
               // ...(data.bccs || []).filter(to => !myEmails.some(e => e.email === to.address)),
               { name: data.fromName, address: data.fromAddress },
            ],
            ccs: data.ccs,
            bccs: data.bccs,
            text,
            // text: "<br/><br/><br/><div style='padding: 16px;border: 1px solid grey;'>" + data.text + "</div>"
         },
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function actForward(data, { history, match }) {
   return (dispatch, getState) => {
      const text = getReplyText(data, getState);

      dispatch({
         type: ACT_FORWARD,
         data: {
            subject: '[FW] ' + data.subject,
            files: data.files,
            text,
            // text: "<br/><br/><br/><div style='padding: 16px;border: 1px solid grey;'>" + data.text + "</div>"
         },
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

function getReplyText(data, getState) {
   const { strings } = getState().gc.common;
   let text = `<p></p><p></p><div style='padding: 16px;border-left: 2px solid grey;'>
         <div style='display: flex; flex-direction: column'>
         <div style='display:flex;flex-direction: row;align-items: center;white-space: pre-wrap;'>
         <strong style='min-width: 55px;align-self: start;'>${strings.mailFrom}</strong>&nbsp;:&nbsp;${
            Boolean(data.fromName) ? `"${data.fromName}"&nbsp;` : ''
         }&lt;${data.fromAddress}&gt;`;

   text += "</div><div style='display:flex;flex-direction: row;align-items: center;white-space: pre-wrap;'>";
   text += "<strong style='min-width: 55px;align-self: start;'>" + strings.mailTo + '</strong>&nbsp;:&nbsp;';
   data.tos.forEach((to, idx) => {
      text += `${idx > 0 ? ', ' : ''}${Boolean(to.searchedName) ? `"${to.searchedName}"&nbsp;` : ''}&lt;${to.address}&gt;`;
   });
   text += '</div>';
   if (data.ccs.length > 0) {
      text +=
         "<div style='display:flex;flex-direction: row;align-items: center;white-space: pre-wrap;'>" +
         `<strong style='min-width: 55px;align-self: start;'>${strings.mailCc}</strong>&nbsp;:&nbsp;`;
      data.ccs.forEach((cc, idx) => {
         text += `${idx > 0 ? ', ' : ''}${Boolean(cc.searchedName) ? `"${cc.searchedName}"&nbsp;` : ''}&lt;${cc.address}&gt;`;
      });
      text += '</div>';
   }

   text +=
      "<div style='display:flex;flex-direction: row;align-items: center;white-space: pre-wrap;'>" +
      `<strong style='min-width: 55px;align-self: start;'>${strings.mailReceivedDate}</strong>&nbsp;:&nbsp;`;
   if (!['send', 'temp'].includes(data.folderName)) {
      text += pack.timestampToString(data.receivedDate, true, true);
   } else {
      text += pack.timestampToString(data.date, true, true);
   }
   text +=
      "</div><div style='display:flex;flex-direction: row;align-items: center;white-space: pre-wrap;'>" +
      `<strong style='min-width: 55px;align-self: start;'>${strings.title}</strong>&nbsp;:&nbsp;` +
      data.subject +
      '</div>' +
      '</div>' +
      '<br/><br/>' +
      decodeURIComponent(encodeURIComponent(data.text)) +
      '</div>';
   return text;
}
/* ACT TYPE 2 */

export function toggleStarred(mail) {
   const starred = mail.starred === '1' ? '0' : '1';

   return (dispatch, getState) => {
      const { boxType } = getState().griffin.activity.mailbox;

      axios({
         method: 'post',
         url: `/mail/starred/${starred}`,
         data: jtf({ seq: mail.seq }),
      })
         .then(res => {
            dispatch({
               type: TOGGLE_STARRED,
               seq: mail.seq,
               starred,
            });

            if (boxType === MAILBOX_TYPE_STAR && starred === '0') {
               dispatch(fetchEmailList());
            }
            dispatch(fetchCounts());

            // dispatch(updateMenuMailCount());
            // dispatch(fetchJustList());
         })
         .catch(e => pack.showError(e));
   };
}

/*export function toggleImportant(mail) {
   const important = mail.important === '1' ? '0' : '1';
   return (dispatch) =>
      axios({
         method: 'post',
         url: `/mail/important/${important}`,
         data: jtf({mailId: mail.id})
      }).then(res => {
         dispatch({
            type: TOGGLE_IMPORTANT,
            id: mail.id,
            important
         });
         // dispatch(updateMenuMailCount());
         dispatch(fetchJustList());
      }).catch(e => pack.showError(e));
}*/

export function permitImageForever(address) {
   return (dispatch, getState) => {
      axios.get(`/mail/permit/${address}/${getState().gc.user.empno}`).then(res => {
         if (res.status === 200)
            dispatch({
               type: PERMIT_USER_IMAGE,
               address,
            });
         else console.log('error occured while Permiting email.\n' + res.error);
      });
   };
}

export function requestSend() {
   return {
      type: SEND_REQUEST,
   };
}

export function requestTempSave(flag) {
   return {
      type: TEMPSAVE_REQUEST,
      flag,
   };
}

export function requestTemplateSave(flag) {
   return {
      type: TEMPLATE_SAVE_REQUEST,
      flag,
   };
}

export function requestOpenTemplate(flag) {
   return {
      type: TEMPLATE_LOAD_REQUEST,
      flag,
   };
}

export function createTemplate(data, onSuccess, onFail) {
   return (dispatch, getState) => {
      dispatch(requestTemplateSave(false));
      axios
         .post('/mail/template', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            if (res.status === 200) {
               dispatch(fetchTemplates());
               onSuccess(res.data);
            } else {
               onFail(res);
            }
         })
         .catch(e => {
            pack.showError(e);
            onFail(e);
         });
   };
}

export function removeTemplate(seq) {
   return (dispatch, getState) => {
      axios.delete(`/mail/template/${seq}`).then(res => {
         if (res.status === 200 && res.data > 0) {
            dispatch({
               type: REMOVE_TEMPLATE,
               seq,
            });
         } else {
            const { strings } = getState().gc.common;
            toastr.error(strings.errTitle, strings.errMessage);
         }
      });
   };
}

export function toggleAlert(flag, msg) {
   return {
      type: TOGGLE_ALERT,
      flag,
      msg,
   };
}

/*export function fetchEmail(seq) {
   return (dispatch, getState) =>
      axios.get(`/mail/${seq}`)
         .then(res =>
            dispatch({
               type: FETCH_EMAIL,
               payload: res.data
            })
         );
}*/

export function sendEmail(data, { history, match, strings }, onSuccess, onFail) {
   return (dispatch, getState) => {
      const { boxType } = getState().griffin.activity.mailbox;
      const { user } = getState().gc;

      axios({
         method: 'post',
         url: '/mail',
         data: jtf({
            ..._.omit(data, ['files', 'email', 'sortDate']),
            from: user.currentEmail,
            name: user.name,
            empno: user.empno,
            fileString: JSON.stringify(data.files),
         }),
      })
         .then(res => {
            if (typeof onSuccess === 'function') {
               onSuccess();
            }
            if (data.files) {
               // 정상적으로 임시저장이나 메일발송을 한 경우 (beforeUnload 에서 호출한게 아닌 경우)
               dispatch(toggleAlert(true, data.istemp === '1' ? strings.mailSaveComplete : strings.mailSendComplete));
            }
            const tos = JSON.parse(data.to);
            if (boxType === MAILBOX_TYPE_SEND || (boxType === MAILBOX_TYPE_TOME && tos.some(to => to.email === user.email))) {
               dispatch(fetchEmailList());
            }
            /*dispatch({
                  type: FETCH_EMAIL,
                  payload: res.data,
               });*/
            /*dispatch({
            type: APPEND_LIST,
            payload: res.data
         });*/
            // dispatch(fetchJustList());
            /*history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            id: null
         }));*/
         })
         .catch(e => {
            if (typeof onFail === 'function') {
               onFail(e);
            }
         });
   };
}

export function toggleSendEnable(enable) {
   return {
      type: TOGGLE_SEND_ENABLE,
      enable,
   };
}

export function toggleSendNow(flag) {
   return {
      type: TOGGLE_SEND_NOW,
      flag,
   };
}

export function toggleTemplateSavable(flag) {
   return {
      type: TOGGLE_TEMPLATE_SAVABLE,
      flag,
   };
}

export function openContactsDialog(name, address) {
   return (dispatch, getState) =>
      axios.get(`/mail/contacts/${address}/${getState().gc.user.empno}`).then(res => {
         return dispatch({
            type: OPEN_CONTACTS_DIALOG,
            name,
            address,
            payload: res.data,
         });
      });
}

export function closeContactsDialog() {
   return {
      type: CLOSE_CONTACTS_DIALOG,
   };
}

export function fetchSigns() {
   return (dispatch, getState) =>
      axios.get(`/mail/signature/list/${getState().gc.user.currentEmail}`).then(res =>
         dispatch({
            type: FETCH_SIGNS,
            payload: res.data,
         }),
      );
}

export function fetchDefaultSign() {
   return (dispatch, getState) => {
      axios.get(`/mail/signature/default/${getState().gc.user.currentEmail}`).then(res =>
         dispatch({
            type: FETCH_DEFAULT_SIGN,
            payload: res.data,
         }),
      );
   };
}

export function updateDefaultSign(seq = 0) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/mail/signature/default',
         data: jtf({ seq, email: getState().gc.user.currentEmail }),
      }).then(res =>
         dispatch({
            type: FETCH_DEFAULT_SIGN,
            payload: res.data,
         }),
      );
   };
}

export function removeSign(seq) {
   return (dispatch, getState) => {
      axios.delete(`/mail/signature/${seq || 0}/${getState().gc.user.currentEmail}`).then(res =>
         dispatch({
            type: REMOVE_SIGN,
            payload: res.data,
         }),
      );
   };
}

export function createSign(data) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: `/mail/signature${data.seq ? '/update' : ''}`,
         data: jtf({
            ..._.pick(data, ['seq', 'no', 'content']),
            email: getState().gc.user.currentEmail,
         }),
      }).then(res => {
         dispatch({
            type: FETCH_SIGNS,
            payload: res.data,
            seq: data.seq,
         });
      });
   };
}

export function reorderFolder(list) {
   return (dispatch, getState) => {
      axios
         .put(
            '/mail/folder/reorder',
            jtf({
               list: list.map(item => item.seq).join(','),
               email: getState().gc.user.currentEmail,
            }),
         )
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: FETCH_FOLDERS,
                  payload: res.data,
               });
            }
         });
   };
}

export function toggleSideExpanded(flag) {
   return {
      type: TOGGLE_SIDE_EXPANDED,
      flag,
   };
}

export function fetchTags() {
   return (dispatch, getState) =>
      axios.get(`/mail/tags/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_TAGS,
            payload: res.data,
         }),
      );
}

export function removeTag(seq) {
   return (dispatch, getState) => {
      axios
         .delete(`/mail/tag/${seq}`)
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: REMOVE_TAG,
                  seq,
               });
            } else {
               const { strings } = getState().gc.common;
               toastr.error(strings.errTitle, strings.errMessage);
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function selectTag(tag) {
   return {
      type: SELECT_TAG,
      tag,
   };
}

export function createTag(data) {
   const isUpdate = Boolean(data.seq);
   return (dispatch, getState) => {
      axios
         .post('/mail/tag', jtf({ ..._.omit(data, ['employee']), empno: getState().gc.user.empno }))
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: isUpdate ? UPDATE_TAG : APPEND_TAG,
                  tag: res.data,
               });
            } else {
               const { strings } = getState().gc.common;
               toastr.error(strings.errTitle, strings.errMessage);
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function mappingTag(mailSeq, tag, isMapped) {
   const request = axios.post(`/mail/tag/${isMapped ? 'unmap' : 'map'}`, jtf({ mailSeq, tagSeq: tag.seq }));
   return (dispatch, getState) => {
      request
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: isMapped ? UNMAP_TAG : MAP_TAG,
                  tag,
               });
            } else {
               const { strings } = getState().gc.common;
               toastr.error(strings.errTitle, strings.errMessage);
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function fetchLoading(flag) {
   return {
      type: FETCH_LOADING,
      flag,
   };
}

export function togglePreview(flag) {
   return {
      type: TOGGLE_PREVIEW,
      flag,
   };
}

export function fetchSearchs() {
   return (dispatch, getState) => {
      axios.get(`/mail/searchs/${getState().gc.user.currentEmail}`).then(res =>
         dispatch({
            type: FETCH_SEARCHS,
            payload: res.data,
         }),
      );
   };
}

export function searchDetail(data, props) {
   return (dispatch, getState) => {
      const { seq, condition } = data;
      condition['search'] = true;
      dispatch(toggleBoxType(MAILBOX_TYPE_SEARCHBOX, null, props, true));
      dispatch({
         type: SEARCH_DETAIL,
         condition,
         seq,
      });
      dispatch(fetchEmailList({ folderName: SECOND_FOLDER_MAP[MAILBOX_TYPE_SEARCHBOX], condition }));
      /*let folderName = null;
      if (Boolean(condition.folder)) {
         const { folders } = getState().griffin.activity.mailbox;
         folderName = (_.find(folders, { seq: +condition.folder }) || {}).name;
      }*/
      /*let boxType = !condition.folder ? MAILBOX_TYPE_TOTAL : MAILBOX_TYPE_ETC;
      Object.keys(DEFAULT_FOLDER_MAP).forEach(key => {
         if (DEFAULT_FOLDER_MAP[key] === condition.folder) {
            boxType = key;
            return false;
         }
      });*/
   };
}

export function saveSearch({ name, condition }) {
   return (dispatch, getState) =>
      axios
         .post(
            '/mail/search',
            jtf({
               name,
               cond: JSON.stringify(condition),
               email: getState().gc.user.currentEmail,
            }),
         )
         .then(res => dispatch(fetchSearchs()));
}

export function removeSearch(seq) {
   return dispatch => axios.delete(`/mail/search/${seq}`).then(res => dispatch(fetchSearchs()));
}

export function toggleEmailFromDialog(flag) {
   return {
      type: TOGGLE_EMAIL_FROM_DIALOG,
      flag,
   };
}

export function addToMailFrom(which, tab) {
   return (dispatch, getState) => {
      let emails = [];
      if (tab === '1') {
         const { checkedMailFromEmp, targetList } = getState().griffin.activity.mailbox;
         emails = targetList
            .filter(item => checkedMailFromEmp.includes(item.id))
            .map(item => ({
               id: item.email,
               text: `${item.name} [${item.email}]`,
               name: item.name,
               desc: `${item.title} [${item.email}]`,
            }));
      } else if (tab === '2') {
         const { checkedMailFromContacts, contactsList } = getState().griffin.activity.mailbox;
         emails = contactsList
            .filter(item => checkedMailFromContacts.includes(item.id))
            .map(item => ({
               id: item.email,
               text: `${item.name} [${item.email}]`,
               name: item.name,
               desc: `${item.title} [${item.email}]`,
            }));
      }
      dispatch({
         type: MAIL_FROM_ADD,
         which,
         emails,
      });
   };
}

export function removeMailFrom(which, ids) {
   return Boolean(ids)
      ? {
           type: MAIL_FROM_REMOVE,
           which,
           ids,
        }
      : {
           type: MAIL_FROM_REMOVE_ALL,
           which,
        };
}

export function setCheckedMailFromEmp(checked) {
   return {
      type: SET_CHECKED_MAILFROM_EMP,
      checked,
   };
}

export function setCheckedMailFromContacts(checked) {
   return {
      type: SET_CHECKED_MAILFROM_CONTACTS,
      checked,
   };
}

export function fetchEmpTree() {
   return (dispatch, getState) => {
      axios.get(`/department/emp-email/tree?lang=${getState().gc.common.lang}`).then(res =>
         dispatch({
            type: FETCH_EMP_TREE,
            tree: res.data.tree,
            list: res.data.list,
         }),
      );
   };
}

export function fetchContactsTree() {
   return (dispatch, getState) =>
      axios.get(`/contacts/tree/${getState().gc.user.empno}?lang=${getState().gc.common.lang}`).then(res =>
         dispatch({
            type: FETCH_CONTACTS_TREE,
            tree: res.data.tree,
            list: res.data.list,
         }),
      );
}

export function initMailFrom() {
   return {
      type: INIT_MAILFROM,
   };
}

export function setPageInfo(boxType, page) {
   return {
      type: SET_PAGE_INFO,
      boxType,
      page,
   };
}

export function onReceiveNewMail(mails, props) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      dispatch(fetchCounts());
      dispatch({
         type: NEW_MAIL_RECEIVED,
         payload: mails,
      });
      /*if (boxType === MAILBOX_TYPE_INBOX) {
         dispatch({
            type: APPEND_LIST,
            payload: mails,
         });
      }*/
      const mailList = mails.length < 5 ? mails : mails.slice(0, 5);
      mailList.forEach(mail => {
         pack.alarm(pack.emailIconImage, strings.pushNewMail, `<${mail.email}>\n${mail.subject}`, 7000);
      });

      pack.notify(
         mailList.map(mail => ({
            title: mail.fromName || mail.fromAddress,
            body: mail.subject,
            onClick: () => {
               if (props)
                  if (props.match.url.startsWith('/activity/mail')) {
                     props.history.push(
                        pack.getToPath(props.match)({
                           ...props.match.params,
                           mode: 'view',
                           seq: mail.seq,
                        }),
                     );
                  } else {
                     props.history.push(`/activity/mail/view/${mail.seq}`);
                  }
            },
         })),
      );
      /*mails.forEach(mail => {
         pack.notify(mail)
         dispatch(notify({
            ...pack.options.notify,
            title: mail.fromName || mail.fromAddress,
            body: mail.subject,
            tag: uuid(),
            onClick: () => {
               if (props)
                  if (props.match.url.startsWith('/activity/mail')) {
                     props.history.push(pack.getToPath(props.match)({
                        ...props.match.params,
                        mode: 'view',
                        seq: mail.seq
                     }));
                  } else {
                     props.history.push(`/activity/mail/view/${mail.id}`);
                  }
            }
         }));
      });*/
   };
}

export function onReceiveRemovedMail(mails) {
   return {
      type: REMOVE_FROM_LIST,
      payload: mails,
   };
}

export function onReceiveSeenMail(mails) {
   return {
      type: NEW_SEEN_LIST,
      payload: mails,
   };
}

export function onConfirmMail(data) {
   return {
      type: CONFIRM_MAIL,
      data,
   };
}
