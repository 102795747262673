import axios from 'axios';
import _ from 'lodash';
import { pack } from '../../../../@gc';
import { setFinishInfo } from '../../../../@gc/common/actions/common.action';
import { useStore } from '../../../../store/globalStore';

export const GET_GOOGLE_EVENTS = '[COMMUTE] GET GOOGLE EVENTS';
export const FETCH_COMMUTES = '[COMMUTE] FETCH COMMUTE LIST';
export const FETCH_COMMUTE = '[COMMUTE] FETCH COMMUTE';
export const START_WORK = '[COMMUTE] START WORK';
export const FINISH_WORK = '[COMMUTE] FINISH WORK';
export const TOGGLE_TREE = '[COMMUTE] TOGGLE TREE';
export const FETCH_EMPLOYEE = '[COMMUTE] FETCH EMPLOYEE';
export const FETCH_TARGET_LIST = '[COMMUTE] FETCH_TARGET_LIST';

export function fetchEvents(yyyyMM, userEmpno) {
   return (dispatch, getState) => {
      const request = axios.get(`/commute/list/${yyyyMM}/${userEmpno || getState().gc.user.empno}`);

      request.then(res => {
         dispatch({
            type: FETCH_COMMUTES,
            payload: res.data,
         });
      });
   };
}

export function fetchEntity(yyyyMMdd, empno) {
   return (dispatch, getState) => {
      axios.get(`/commute/day/${yyyyMMdd}/${empno || getState().gc.user.empno}`).then(res => {
         const { data } = res;
         let myEntity = null;
         if (Boolean(data) && data.employee.empno === getState().gc.user.empno) {
            myEntity = data;
         }
         dispatch({
            type: FETCH_COMMUTE,
            payload: res.data,
            myEntity,
            day: yyyyMMdd,
         });
      });
   };
}

export function fetchEmployee(empno) {
   return (dispatch, getState) => {
      const date = getState().griffin.manage.commute.baseDay;
      dispatch(fetchEvents(date.substr(0, 6), empno));
      dispatch(fetchEntity(date, empno));

      axios.get(`/emp/${empno}`).then(res => {
         dispatch({
            type: FETCH_EMPLOYEE,
            payload: res.data,
         });
      });
   };
}

export function fetchTargetList() {
   return (dispatch, getState) => {
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`).then(res =>
         dispatch({
            type: FETCH_TARGET_LIST,
            tree: res.data.tree,
            list: res.data.list,
         }),
      );
   };
}

export function startWork() {
   return (dispatch, getState) => {
      const { connectInfo } = useStore.getState();
      const data = {
         ip: connectInfo.ip,
         info: JSON.stringify(connectInfo),
      };
      axios.post(`/commute/start/${getState().gc.user.id}`, JSON.stringify(data)).then(res => {
         dispatch({
            type: START_WORK,
            payload: res.data,
         });
         dispatch(fetchEvents(getState().griffin.manage.commute.baseDay.substr(0, 6)));
      });
   };
}

export function finishWork() {
   return dispatch => {
      axios
         .get('https://ipapi.co/json', {
            withCredentials: false,
            baseURL: '',
         }) // ipapi 지원중단시 대체 API : https://www.abstractapi.com/api/ip-geolocation-api  (구글에서도 사용하는 유료 API 임)
         .then(res => {
            const info = _.pick(res.data, ['ip']);
            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(
                  position => {
                     info.latitude = position.coords.latitude;
                     info.longitude = position.coords.longitude;
                     dispatch(postFinishWork(info));
                  },
                  error => {
                     console.log('geolocation error', error);
                     info.latitude = res.data.latitude;
                     info.longitude = res.data.longitude;
                     dispatch(postFinishWork(info));
                  },
               );
            } else {
               info.latitude = res.data.latitude;
               info.longitude = res.data.longitude;
               dispatch(postFinishWork(info));
            }
         })
         .catch(pack.showError);
   };
}

function postFinishWork(connectInfo) {
   return (dispatch, getState) => {
      dispatch(
         setFinishInfo(connectInfo, newInfo => {
            const data = {
               ip: newInfo.ip,
               info: JSON.stringify(newInfo),
            };
            axios.post(`/commute/finish/${getState().gc.user.id}`, JSON.stringify(data)).then(res => {
               dispatch({
                  type: FINISH_WORK,
                  payload: res.data,
               });
               dispatch(fetchEvents(getState().griffin.manage.commute.baseDay.substr(0, 6)));
            });
         }),
      );
   };
}

export function toggleTree(open) {
   return {
      type: TOGGLE_TREE,
      open,
   };
}
