import React from 'react';
import { create } from 'zustand';
import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { pack } from '../@gc';
import { getAddressFromLatLng } from '../@gc/common/actions/common.action';
import { createJSONStorage, persist } from 'zustand/middleware';

export const GEO_NOT_READY = -1;
export const GEO_GRANTED = 1;
export const GEO_DENIED = 0;
export const GEO_NOT_SUPPORTED = 9;
// const { kakao } = window;

export const useStore = create(set => ({
   logout: false,
   geoStatus: GEO_NOT_READY,
   setGeoStatus: status => set(_ => ({ geoStatus: status })),
   connectInfo: undefined, // 접속 당시의 위치
   resetConnectInfo: () => set(_ => ({ connectInfo: undefined })),
   setConnectInfo: () => {
      axios
         .get('https://ipapi.co/json', {
            withCredentials: false,
            baseURL: '',
         }) // ipapi 지원중단시 대체 API : https://www.abstractapi.com/api/ip-geolocation-api  (구글에서도 사용하는 유료 API 임)
         .then(res => {
            const info = _.pick(res.data, ['ip']);
            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(
                  position => {
                     info.latitude = position.coords.latitude;
                     info.longitude = position.coords.longitude;
                     getAddressFromLatLng(info.latitude, info.longitude, (result, status) => {
                        // eslint-disable-next-line no-undef
                        if (status === kakao.maps.services.Status.OK) {
                           info.address = result[0].road_address?.address_name || result[0].address.address_name;
                           set(_ => ({
                              connectInfo: info,
                              geoStatus: GEO_GRANTED,
                           }));
                        }
                     });
                  },
                  error => {
                     if (error.code === error.PERMISSION_DENIED) {
                        toastr.warning('경고', {
                           timeOut: 5000,
                           position: 'top-right',
                           component: (
                              <span>
                                 <span className="text-danger font-weight-bold">위치 권한</span>이 허용되지 않았습니다
                              </span>
                           ),
                        });
                        set(_ => ({
                           geoStatus: GEO_DENIED,
                           connectInfo: undefined,
                        }));
                     } else {
                        console.log('geolocation error=', error);
                        toastr.error('알림', `위치 권한에 접근중 오류 발생 (코드: ${error.code})`);
                        set(_ => ({ logout: true }));
                        setTimeout(() => set(_ => ({ logout: false })), 500);
                     }
                  },
               );
            } else {
               toastr.info('알림', '지원되지 않는 브라우저입니다');
               set(_ => ({ logout: true, geoStatus: GEO_NOT_SUPPORTED }));
               setTimeout(() => set(_ => ({ logout: false })), 500);
            }
         })
         .catch(pack.showError);
   },
}));

export const usePersist = create()(
   persist(
      set => ({
         geoStatus: GEO_NOT_READY,
         setGeoStatus: status => set(_ => ({ geoStatus: status })),
      }),
      {
         name: 'local-storage',
         storage: createJSONStorage(() => localStorage),
      },
   ),
);
